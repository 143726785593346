import theme from "@elegantstack/flow-ui-theme/src/theme/index"
//Using tailwind preset colors
import colorPreset from "@elegantstack/flow-ui-theme/src/theme/color-preset"
export default {
    ...theme,
    colors: {
        ...theme.colors,
        alphaLighter: colorPreset.orange[100],
        alphaLight: colorPreset.orange[300],
        alpha: colorPreset.orange[600],
        alphaDark: colorPreset.orange[800],
        alphaDarker: colorPreset.orange[900],
    },
}